import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DoctorConsultingHour } from '../updatedoctor/doctorconsultinghour';
import { Router } from '@angular/router';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService } from '@prekog/pw-common';
import { Doctor } from '../doctor';
import { FindDoctorRequest } from '../updatedoctor/finddoctorrequest';
import { FindDoctorResponse } from '../updatedoctor/finddoctorresponse';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { AddConsultingHourRequest } from './addconsultinghourrequest';
import { AddConsultingHourResponse } from './addconsultinghourresponse';
import { FIndConsultingHoursRequest } from '../updatedoctor/findConsultingHoursRequest';
import { FindConsultingHoursResponse } from '../updatedoctor/findConsultingHoursResponse';
import { DeleteconsultinghourpopupComponent } from '../deleteconsultinghourpopup/deleteconsultinghourpopup.component';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-addconsultationhour',
    templateUrl: './addconsultationhour.component.html',
    styleUrls: ['./addconsultationhour.component.scss']
})
export class AddconsultationhourComponent implements OnInit {


    consultationHourForm?: FormGroup;

    doctorConsultingHour: DoctorConsultingHour = {};

    regeneroBaseUrl = '';

    id = 0;

    doctor: Doctor = {};

    findDoctorUrl = '/regenero/find-doctor';

    addConsultingHourUrl = '/admin/add-consulting-hour';

    doctorConsultingHoursList?:DoctorConsultingHour[];

    findAllConsultingHoursUrl = '/regenero/find-consulting-hour';

    isExtraSmall: Observable<BreakpointState>;

    dialogConfiger: MatDialogConfig = {
        width: '60%',
        backdropClass: 'back-drop-background',
        hasBackdrop: true
    };
    enterAnimationDuration = '500ms';
    exitAnimationDuration = '200ms';

    constructor(public _router: Router,
        private readonly breakpointObserver: BreakpointObserver,
        private _matDialog: MatDialog,
        private _apiRequestService: ApiRequestService,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private _toastMessageService: ToastmessageService, private _fb: FormBuilder) {
        this.regeneroBaseUrl = _regeneroBaseUrl;
        this.createConsultationHourForm();
        this.isExtraSmall = this.breakpointObserver.observe(Breakpoints.XSmall);
    }

    ngOnInit(): void {
        this.findDoctor();
        this.getConsultingHours();
    }

    findDoctor() {
        const lastSegmentOfUrl = window.location.href.split('/').pop();
        const routerLink = lastSegmentOfUrl as string;
        this.id = +routerLink;
        const findDoctorRequest: FindDoctorRequest = {
            id: this.id
        };

        this._apiRequestService
            .sendRequest<FindDoctorRequest, FindDoctorResponse>(this.regeneroBaseUrl, true, findDoctorRequest, true, this.findDoctorUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response: FindDoctorResponse) => {
                this.doctor = response.doctor!;

            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    createConsultationHourForm() {
        this.consultationHourForm = this._fb.group({
            day: ['', [Validators.required]],
            startTime: ['', [Validators.required]],
            endTime: ['', [Validators.required]]
        });
    }

    collectConsultationHourData() {
        this.doctorConsultingHour = {
            day: this.consultationHourForm?.controls['day'].value as string | undefined,
            startTime: this.consultationHourForm?.controls['startTime'].value as string | undefined,
            endTime: this.consultationHourForm?.controls['endTime'].value as string | undefined,
            doctorId: this.id
        };

    }

    openDeleteConsultingPopup(id:number) {
        const popupDialog = this._matDialog.open(DeleteconsultinghourpopupComponent, {
            data: id,
            width: 'calc(100% - 50px)',
            maxWidth: '100vw',
            height: '80%'
        });
        const xsmallDialogSubscription = this.isExtraSmall.subscribe((size:any) => {
            if (size.matches) {
                popupDialog.updateSize('100vw', '100%');
            } else {
                popupDialog.updateSize('calc(60% - 50px)', '');
            }
        });

        popupDialog.afterClosed().subscribe(() => {
            xsmallDialogSubscription.unsubscribe();
        });
    }

    getConsultingHours() {
        const findConsultingHoursRequest: FIndConsultingHoursRequest = {
            doctorId: this.id
        }

        this._apiRequestService
            .sendRequest<FIndConsultingHoursRequest, FindConsultingHoursResponse>(this.regeneroBaseUrl, true, findConsultingHoursRequest, true, this.findAllConsultingHoursUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response: FindConsultingHoursResponse) => {
                this.doctorConsultingHoursList = response.doctorConsultingHourList!;

            });
    }

    //TODO add date variable, speak about it with Csabi!
    addConsultationHour() {
        this.collectConsultationHourData();

        const addConsultingHourRequest: AddConsultingHourRequest = {
            doctorConsultingHour: this.doctorConsultingHour
        }

        this._apiRequestService
            .sendRequest<AddConsultingHourRequest, AddConsultingHourResponse>(this.regeneroBaseUrl, true, addConsultingHourRequest, true, this.addConsultingHourUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response: AddConsultingHourResponse) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage!, MAT_ICON.success);
                this.dismissError();
                this._router.navigateByUrl('/doctor/'+this.id);

            });
    }
}
