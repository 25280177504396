import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ApiRequestService, TOAST_STATE, ToastmessageService, HttpError, MAT_ICON, TranslationService } from '@prekog/pw-common';
import { FindBannerRequest } from './findbannerrequest';
import { FindBannerResponse } from './findbannerresponse';
import { BannerConfig } from '../orderbanners/bannerconfig';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UpdateBannerRequest } from './updatebannerRequest';
import { UpdateBannerResponse } from './updatebannerresponse';
import { UploadToAnExistingFolderRequest } from '../uploadtoanexistingfolderrequest';
import { UploadToAnExistingFolderResponse } from '../uploadtoanexistingfolderresponse';
import { UploadFileResponse } from '../uploadfileresponse';

@Component({
    selector: 'app-updatebanner',
    templateUrl: './updatebanner.component.html',
    styleUrls: ['./updatebanner.component.scss']
})
export class UpdatebannerComponent implements OnInit {
    configuratorBaseUrl = '';

    findBannerUrl = '/admin/find-banner';

    bannerConfig: BannerConfig = {};

    bannerForm?: FormGroup;

    @ViewChild('fileCoverInput') fileCoverInput!: ElementRef;
    fileCover: File | null = null;

    updateCoverFile = false;

    uploadFileToAnExistingFolderUrl = '/admin/updatefile';

    uploadFileToAnExistingFolderRequest: UploadToAnExistingFolderRequest = {};

    uploadFileToAnExistingFolderResponse: UploadToAnExistingFolderResponse = {};

    fileBaseUrl = '';

    uploadFileResponse: UploadFileResponse = {};

    downloadUri = '';

    url = '';

    maxFileSize = 5000000;

    updateBannerUrl = '/admin/update-banner';

    constructor(
        private _toastMessageService: ToastmessageService,
        private _apiRequestService: ApiRequestService,
        @Inject('CONFIGURATOR_BASE_URL') private _configuratorBaseUrl: string,
        @Inject('FILE_MANAGER_URL') private _fileManagerUrl: string,
        private _fb: FormBuilder,
        private _router: Router,
        private _translateService: TranslationService
    ) {
        this.configuratorBaseUrl = _configuratorBaseUrl;
        this.fileBaseUrl = _fileManagerUrl;
        this.createBannerForm();
    }

    ngOnInit(): void {
        if (sessionStorage.getItem('token')) {
            const lastSegmentOfUrl = window.location.href.split('/').pop();
            const id = Number(lastSegmentOfUrl as string);

            const findBannerRequest: FindBannerRequest = {
                id: id
            };

            this._apiRequestService
                .sendRequest<FindBannerRequest, FindBannerResponse>(
                    this.configuratorBaseUrl,
                    true,
                    findBannerRequest,
                    true,
                    this.findBannerUrl
                )
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        return throwError(() => {
                            const httpError: HttpError = error.error as HttpError;

                            this._toastMessageService.displayToastMessage(
                                TOAST_STATE.error,
                                'Sikertelen művelet' + httpError.errorMessage,
                                MAT_ICON.error
                            );
                            this.dismissError();
                        });
                    })
                )
                .subscribe((response) => {
                    this.bannerConfig = response.bannerConfig as BannerConfig;
                });
        } else {
            this._router.navigateByUrl('/');
        }
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    createBannerForm() {
        this.bannerForm = this._fb.group({
            title: ['', [Validators.required]],
            link: ['', [Validators.required]]
        });
    }

    onChange(event: Event) {
        const target = event.target as HTMLInputElement;
        this.fileCover = target.files![0];
        if (this.fileCover.name.split('.').pop() === 'jpg') {
            this.updateCoverFile = true;
        }
        else {
            this.updateCoverFile = false;
            this._toastMessageService.displayToastMessage(TOAST_STATE.warning, 'Kérem ".jpg" kiterjesztésű fájlt töltsön fel!', MAT_ICON.warning);
            this.dismissError();
        }
        this.updateCoverFile = true;
    }

    clearSelectedCoverFile() {
        this.fileCoverInput.nativeElement.value = null;
        this.updateCoverFile = false;
    }

    updateBanner(bannerConfig: BannerConfig) {
        this.collectBannerData(bannerConfig);
        const updateBannerRequest: UpdateBannerRequest = {
            bannerConfig: bannerConfig
        };
        this._apiRequestService
            .sendRequest<UpdateBannerRequest, UpdateBannerResponse>(
                this.configuratorBaseUrl,
                true,
                updateBannerRequest,
                true,
                this.updateBannerUrl
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                            MAT_ICON.error
                        );
                        this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage as string, MAT_ICON.success);
                this.dismissError();
                if (this.fileCover?.size! > this.maxFileSize) {
                    this._toastMessageService.displayToastMessage(
                        TOAST_STATE.error,
                        this._translateService.translate('A maximum fájl korlát 5MB. Kérem ennél kisebb méretű fájlt töltsön fel!'),
                        MAT_ICON.error
                    );
                    this.dismissError();
                    return;
                }
                if (this.fileCover !== null && this.fileCover.size < this.maxFileSize) {
                    this.updateFile(bannerConfig.imageUrl!);
                }
            });
    }


    updateFile(downloadUrl: string) {
        //TODO call file manager to get downloadUri
        const folderName = downloadUrl.split('/').pop() as string;

        this._apiRequestService
            .uploadFileWithSpringBootRequestParts(
                this.fileCover!,
                folderName,
                true,
                true,
                this.fileBaseUrl,
                this.uploadFileToAnExistingFolderUrl
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                            MAT_ICON.error
                        );
                        this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response) => {
                this.uploadFileResponse = response as UploadFileResponse;
                this.downloadUri = this.uploadFileResponse.downloadUri as string;
                if (this.uploadFileResponse.downloadUri) {
                    this._toastMessageService.displayToastMessage(
                        TOAST_STATE.success,
                        'Sikeres fájl feltöltés ' + this.uploadFileResponse.fileName!,
                        MAT_ICON.success
                    );
                    this.dismissError();
                    this.url = this.downloadUri;

                    this.updateCoverFile = false;
                }
            });

    }

    collectBannerData(bannerConfig: BannerConfig) {
        bannerConfig = {
            title: this.bannerForm?.controls['title'].value as string | undefined,
            link: this.bannerForm?.controls['link'].value as string | undefined
        };

        if (this.url) {
            this.bannerConfig.imageUrl = this.url;
        }
    }
}
