import { Component } from '@angular/core';

@Component({
  selector: 'app-updatespeciality',
  templateUrl: './updatespeciality.component.html',
  styleUrls: ['./updatespeciality.component.scss']
})
export class UpdatespecialityComponent {

}
