import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Doctor } from '../doctor';
import { Router } from '@angular/router';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService, TranslationService } from '@prekog/pw-common';
import { DoctorConsultingHour } from './doctorconsultinghour';
import { FindDoctorResponse } from './finddoctorresponse';
import { FIndConsultingHoursRequest } from './findConsultingHoursRequest';
import { FindConsultingHoursResponse } from './findConsultingHoursResponse';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UpdateDoctorRequest } from './updatedoctorrequest';
import { UpdateDoctorResponse } from './updatedoctorresponse';
import { UploadFileResponse } from './uploadfileresponse';
import { FindDoctorRequest } from './finddoctorrequest';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { DeleteconsultinghourpopupComponent } from '../deleteconsultinghourpopup/deleteconsultinghourpopup.component';
import { Observable } from 'rxjs';
import { GetDoctorSpecialitiesRequest } from './getdoctorspecialitiesrequest';
import { GetDoctorSpecialitiesResponse } from './getdoctorspecialitiesresponse';
import { Speciality } from '../../specialitypricelist/speciality';


@Component({
    selector: 'app-updatedoctor',
    templateUrl: './updatedoctor.component.html',
    styleUrls: ['./updatedoctor.component.scss']
})
export class UpdatedoctorComponent implements OnInit {

    doctorForm?: FormGroup;

    doctor: Doctor = {};

    updateDoctorUrl = '/admin/update-doctor';

    findDoctorUrl = '/regenero/find-doctor';

    findAllConsultingHoursUrl = '/regenero/find-consulting-hour';

    getSpecialitiesForADoctorUrl = '/admin/get-doctor-specialities';

    regeneroBaseUrl = '';

    downloadFileUrl = '';

    id = 0;

    doctorConsultingHoursList: DoctorConsultingHour[] = [];

    doctorImage = '';

    @ViewChild('fileCoverInput') fileCoverInput!: ElementRef;
    fileCover: File | null = null;

    updateCoverFile = false;

    consultingHourForm?: FormGroup;

    uploadFileUrl = '/admin/uploadFile';

    deleteContentOfFolderUrl = '/admin/deletefoldercontent';

    fileBaseUrl = '';

    uploadFileToAnExistingFolderUrl = '/admin/updatefile';

    maxFileSize = 5000000;

    isExtraSmall: Observable<BreakpointState>;

    dialogConfiger: MatDialogConfig = {
        width: '60%',
        backdropClass: 'back-drop-background',
        hasBackdrop: true
    };
    enterAnimationDuration = '500ms';
    exitAnimationDuration = '200ms';

    html = '';

    htmlForm?: FormGroup;

    doctorSpecialityList?: Speciality[];

    get items() {
        return this.consultingHourForm?.get('items') as FormArray;
    }

    constructor(
        public _router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('DOWNLOAD_FILE_URL') private _downloadFileUrl: string,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private _toastMessageService: ToastmessageService,
        private _fb: FormBuilder,
        @Inject('FILE_MANAGER_URL') private _fileManagerUrl: string,
        private readonly breakpointObserver: BreakpointObserver,
        private _translateService: TranslationService,
        private _matDialog: MatDialog,
    ) {
        this.downloadFileUrl = _downloadFileUrl;
        this.regeneroBaseUrl = _regeneroBaseUrl;
        this.fileBaseUrl = _fileManagerUrl;
        this.isExtraSmall = this.breakpointObserver.observe(Breakpoints.XSmall);
    }

    ngOnInit(): void {
        this.findDoctor();
        this.createDoctorForm();
        this.createConsultingHourFormArray();
        this.createHtmlForm();
    }

    createHtmlForm() {
        this.htmlForm = this._fb.group({
            html: ['', [Validators.required]],

        });
    }

    findDoctor() {
        const lastSegmentOfUrl = window.location.href.split('/').pop();
        const routerLink = lastSegmentOfUrl as string;
        this.id = +routerLink;
        const findDoctorRequest: FindDoctorRequest = {
            id: this.id
        };

        this._apiRequestService
            .sendRequest<FindDoctorRequest, FindDoctorResponse>(this.regeneroBaseUrl, true, findDoctorRequest, true, this.findDoctorUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response: FindDoctorResponse) => {
                this.doctor = response.doctor!;
                this.getConsultingHours(this.id);

            });
    }

    getConsultingHours(id: number) {
        const findConsultingHoursRequest: FIndConsultingHoursRequest = {
            doctorId: id
        }

        this._apiRequestService
            .sendRequest<FIndConsultingHoursRequest, FindConsultingHoursResponse>(this.regeneroBaseUrl, true, findConsultingHoursRequest, true, this.findAllConsultingHoursUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response: FindConsultingHoursResponse) => {
                this.doctorConsultingHoursList = response.doctorConsultingHourList!;
                this.consultingHourForm = this._fb.group({
                    items: this._fb.array(this.doctorConsultingHoursList!.map(consultingHours => this._fb.group(consultingHours)))
                });
                this.getSpecialitiesForDoctor(this.id);
            });
    }

    getSpecialitiesForDoctor(id: number) {
        const getSpecialitiesDoctorRequest: GetDoctorSpecialitiesRequest = {
            doctorId: id
        }

        this._apiRequestService
            .sendRequest<GetDoctorSpecialitiesRequest, GetDoctorSpecialitiesResponse>(this.regeneroBaseUrl, true, getSpecialitiesDoctorRequest, true, this.getSpecialitiesForADoctorUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response: GetDoctorSpecialitiesResponse) => {
                this.doctorSpecialityList = response.specialityList!;

            });
    }


    openDeleteConsultingPopup(id: number) {
        const popupDialog = this._matDialog.open(DeleteconsultinghourpopupComponent, {
            data: id,
            width: 'calc(100% - 50px)',
            maxWidth: '100vw',
            height: '80%'
        });
        const xsmallDialogSubscription = this.isExtraSmall.subscribe((size: any) => {
            if (size.matches) {
                popupDialog.updateSize('100vw', '100%');
            } else {
                popupDialog.updateSize('calc(60% - 50px)', '');
            }
        });

        popupDialog.afterClosed().subscribe(() => {
            xsmallDialogSubscription.unsubscribe();
        });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    createDoctorForm() {
        this.doctorForm = this._fb.group({
            name: ['', [Validators.required]],
            reservationLink: ['', [Validators.required]],
            routerLink: ['', [Validators.required]]

        });
    }

    collectDoctorData() {
        const doctorId = this.doctor.id!;
        if (!this.doctorImage) {
            this.doctorImage = this.doctor.image!
        }
        this.doctor = {
            id: doctorId,
            name: this.doctorForm?.controls['name'].value as string | undefined,
            introduction: this.htmlForm?.controls['html'].value as string | undefined,
            reservationLink: this.doctorForm?.controls['reservationLink'].value as string | undefined,
            image: this.doctorImage,
            routerLink:this.doctorForm?.controls['routerLink'].value as string | undefined


        };
    }

    createConsultingHourFormArray() {
        this.consultingHourForm = this._fb.group({
            items: this._fb.array([this.consultingHourForm])
        });
    }

    updateDoctorPart() {
        this.collectDoctorData();

        const updateDoctorRequest: UpdateDoctorRequest = {
            doctor: this.doctor,
            consultingHourList: this.items.value as DoctorConsultingHour[]
        }

        this._apiRequestService
            .sendRequest<UpdateDoctorRequest, UpdateDoctorResponse>(this.regeneroBaseUrl, true, updateDoctorRequest, true, this.updateDoctorUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response: UpdateDoctorResponse) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage!, MAT_ICON.success);
                this.dismissError();

            });
    }

    clearSelectedCoverFile() {
        this.fileCoverInput.nativeElement.value = null;
        this.updateCoverFile = false;
    }

    onChange(event: Event) {
        const target = event.target as HTMLInputElement;
        this.fileCover = target.files![0];
        if (this.fileCover.name.split('.').pop() === 'jpg' || 'png') {
            this.updateCoverFile = true;
        }
        else {
            this.updateCoverFile = false;
            this._toastMessageService.displayToastMessage(TOAST_STATE.warning, 'Kérem ".jpg" vagy ".png"+ kiterjesztésű fájlt töltsön fel!', MAT_ICON.warning);
            this.dismissError();
        }

    }

    updateDoctor() {
        if (this.fileCover?.size! > this.maxFileSize) {
            this._toastMessageService.displayToastMessage(
                TOAST_STATE.error,
                this._translateService.translate('A maximum fájl korlát 5MB. Kérem ennél kisebb méretű fájlt töltsön fel!'),
                MAT_ICON.error
            );
            this.dismissError();
            return;
        }
        if (this.fileCover !== null && this.fileCover.size < this.maxFileSize) {
            const folderName = this.doctor.image!.split('/').pop() as string;

            this._apiRequestService.uploadFileWithSpringBootRequestParts(this.fileCover!, folderName, true, true, this.fileBaseUrl, this.uploadFileToAnExistingFolderUrl)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        return throwError(() => {
                            const httpError: HttpError = error.error as HttpError;

                            this._toastMessageService.displayToastMessage(TOAST_STATE.error, this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage), MAT_ICON.error);
                            this.dismissError();


                            this._router.navigateByUrl('/');
                        });
                    })
                )
                .subscribe((response) => {
                    const uploadFileResponse = response as UploadFileResponse;
                    this.doctorImage = uploadFileResponse.downloadUri as string;
                    if (uploadFileResponse.downloadUri) {
                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.success,
                            'Sikeres fájl feltöltés ' + uploadFileResponse.fileName!,
                            MAT_ICON.success
                        );
                        this.dismissError();

                        this.updateCoverFile = false;
                        this.updateDoctorPart();
                    }

                });

        }
        else {
            this.updateDoctorPart();
        }

    }

    addConsultationHour() {
        this._router.navigateByUrl('/addconsultationhour/' + this.id)
    }

}
