<div class="contact-content">
    <div class="back-button">
        <mat-icon routerLink="/doctor/{{ id }}">arrow_back</mat-icon>
    </div>
    <div>
        <h3>{{ doctor.name! | uppercase }} - KONZULTÁCIÓS IDŐ</h3>
    </div>
    <h3>Jelenlegi konzultációs idők</h3>
    <div [ngStyle]="{ 'margin-bottom': '32px' }" class="array" *ngFor="let consultationHour of doctorConsultingHoursList; let i = index">
        <div
            class="form-element"
            [ngStyle]="i % 2 === 0 ? { background: 'white', display: 'flex' } : { background: '#efeff5', display: 'flex' }"
        >
            <div class="field-container">
                <div class="field">
                    <span>Konzultáció napja</span>
                    <div class="data">
                        <input placeholder="{{ consultationHour.day }}" type="text" disabled />
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Konzultáció kezdete</span>
                    <div *ngIf="consultationHour.startTime !== null" class="data">
                        <input placeholder="{{ consultationHour.startTime }}" type="text" disabled />
                    </div>
                    <div *ngIf="consultationHour.startTime === null" class="data">
                        <input disabled placeholder="változó" />
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Konzultáció vége</span>
                    <div *ngIf="consultationHour.endTime !== null" class="data">
                        <input [placeholder]="consultationHour.endTime" type="text" disabled />
                    </div>
                    <div *ngIf="consultationHour.endTime === null" class="data">
                        <input type="text" disabled placeholder="változó" />
                    </div>
                </div>
                <mat-icon [ngStyle]="{ cursor: 'pointer' }" (click)="openDeleteConsultingPopup(consultationHour.id!)"
                    >delete_forever</mat-icon
                >
            </div>
        </div>
    </div>
    <h3>Új konzultációs idő hozzáadása</h3>
    <div class="contact-form">
        <form [formGroup]="consultationHourForm!" novalidate>
            <div class="field-container">
                <div class="field">
                    <span>Konzultáció napja</span>
                    <div class="data">
                        <input
                            [(ngModel)]="doctorConsultingHour.day"
                            formControlName="day"
                            id="day"
                            class="form-control"
                            type="text"
                            required
                        />
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Konzultáció kezdete</span>
                    <div class="data">
                        <mat-timepicker></mat-timepicker>
                        <input
                            [(ngModel)]="doctorConsultingHour.startTime"
                            formControlName="startTime"
                            id="startTime"
                            class="form-control"
                            type="time"
                            required
                        />
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Konzultáció vége</span>
                    <div class="data">
                        <input
                            [(ngModel)]="doctorConsultingHour.endTime"
                            formControlName="endTime"
                            id="endTime"
                            class="form-control"
                            type="time"
                            required
                        />
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div [ngStyle]="{ 'margin-top': '8px' }" class="field-container">
        <div [ngStyle]="{ 'justify-content': 'center', 'margin-top': '8px', 'margin-bottom': '8px' }" class="field">
            <div [ngStyle]="{ 'justify-content': 'center' }" class="data">
                <button
                    (click)="addConsultationHour()"
                    [ngStyle]="{ background: '#eee', border: '1px solid #0E8388', 'font-weight': 'bold', width: '100%' }"
                    [disabled]="!consultationHourForm?.valid"
                >
                    KONZULTÁCIÓS IDŐ MENTÉSE
                </button>
            </div>
        </div>
    </div>
</div>
