import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import localeHu from '@angular/common/locales/hu';
import localeHuExtra from '@angular/common/locales/extra/hu';
import { registerLocaleData } from '@angular/common';
import { environment } from 'src/environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import {
    BreadcrumbModule,
    FooterModule,
    HeadermoduleModule,
    IntroductionModule,
    LanguageModule,
    LogLevel,
    SharedModule,
    TranslationModule,
    SlideshowModule,
    NavigationModule,
    ToastmessageModule
} from '@prekog/pw-common';
import { MAT_RADIO_DEFAULT_OPTIONS,MatRadioModule } from '@angular/material/radio';
import { RouterModule } from '@angular/router';
import { GoogleMapsModule } from '@angular/google-maps';
import { HomeComponent } from './home/home.component';
import { BannersComponent } from './banners/banners.component';
import { AddbannerComponent } from './banners/addbanner/addbanner.component';
import { OrderBannersComponent } from './banners/orderbanners/orderbanners.component';
import { UpdatebannerComponent } from './banners/updatebanner/updatebanner.component';
import { DeletebannerpopupComponent } from './banners/deletebannerpopup/deletebannerpopup.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PricelistComponent } from './pricelist/pricelist.component';
import { AddexaminationComponent } from './pricelist/addexamination/addexamination.component';
import { DoctorsComponent } from './doctors/doctors.component';
import { UpdatedoctorComponent } from './doctors/updatedoctor/updatedoctor.component';
import { AddconsultationhourComponent } from './doctors/addconsultationhour/addconsultationhour.component';
import { DeleteconsultinghourpopupComponent } from './doctors/deleteconsultinghourpopup/deleteconsultinghourpopup.component';
import { AdddoctorComponent } from './doctors/adddoctor/adddoctor.component';
import { NgxEditorModule } from 'ngx-editor';
import { HtmleditorComponent } from './htmleditor/htmleditor.component';
import { AddjobtitleComponent } from './doctors/addjobtitle/addjobtitle.component';
import { AddconsultinghourpopupTsComponent } from './doctors/addconsultinghourpopup.ts/addconsultinghourpopup.ts.component';
import { AddspecialityComponent } from './specialities/addspeciality/addspeciality.component';
import { SpecialityPriceListComponent } from './specialitypricelist/specialitypricelist.component';
import { SpecialitiesComponent } from './specialities/specialities.component';
import { UpdatespecialityComponent } from './specialities/updatespeciality/updatespeciality.component';

registerLocaleData(localeHu, 'hu-Hu', localeHuExtra);

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        BannersComponent,
        AddbannerComponent,
        OrderBannersComponent,
        UpdatebannerComponent,
        DeletebannerpopupComponent,
        PricelistComponent,
        SpecialityPriceListComponent,
        AddspecialityComponent,
        AddexaminationComponent,
        DoctorsComponent,
        UpdatedoctorComponent,
        AddconsultationhourComponent,
        DeleteconsultinghourpopupComponent,
        AdddoctorComponent,
        HtmleditorComponent,
        AddjobtitleComponent,
        AddconsultinghourpopupTsComponent,
        SpecialitiesComponent,
        UpdatespecialityComponent

    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        RouterModule,
        ReactiveFormsModule,
        BrowserModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatDividerModule,
        MatDialogModule,
        MatSelectModule,
        MatMenuModule,
        MatFormFieldModule,
        MatCheckboxModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        TranslationModule.forRoot({ translatedLanguages: ['hu'] }),
        LanguageModule.forRoot(),
        BreadcrumbModule.forRoot(),
        FooterModule,
        HeadermoduleModule,
        NavigationModule,
        AppRoutingModule,
        SharedModule.forRoot({ logLevel: LogLevel.Info, enableUseCaseStatusLogs: true }), //TODO: from environment.properties
        IntroductionModule.forRoot({isRowView:false}),
        SlideshowModule,
        MatRadioModule,
        GoogleMapsModule,
        DragDropModule,
        ToastmessageModule,
        NgxEditorModule


    ],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} },
        {
            provide: LOCALE_ID,
            useValue: 'hu-HU'
        },
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: { color: 'black' }
        },
        { provide: 'CONFIGURATOR_BASE_URL', useValue: environment.backendConfiguratorUrl },
        { provide: 'API_URL', useValue: environment.apiUrl },
        { provide: 'DOWNLOAD_FILE_URL', useValue: environment.downloadUrl },
        { provide: 'PRODUCTION', useValue: environment.production },
        { provide: 'REGENERO_BASE_URL', useValue: environment.regeneroBaseUrl },
        { provide: 'AUTH_BACKEND_URL', useValue: environment.backendAuthUrl },
        { provide: 'FILE_MANAGER_URL', useValue: environment.backendFileManagerUrl }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule {}
