<div class="contact-content">
    <div class="back-button">
        <mat-icon routerLink="/doctors">arrow_back</mat-icon>
    </div>
    <div class="name">
        <h3>{{ doctor.name }} adatainak módosítása</h3>
    </div>
    <div class="contact-form">
        <form [formGroup]="doctorForm!" novalidate>
            <div class="field-container">
                <div class="field">
                    <b>Orvos neve</b>
                    <div class="data">
                        <input [(ngModel)]="doctor.name" formControlName="name" id="name" class="form-control" type="text" required />
                    </div>
                </div>
            </div>

            <div class="field-container">
                <div class="field">
                    <b>Orvos foglalási linkje</b>
                    <div class="data">
                        <input
                            [(ngModel)]="doctor.reservationLink"
                            formControlName="reservationLink"
                            id="reservationLink"
                            class="form-control"
                            type="text"
                            required
                        />
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <b>Orvos url-e</b>
                    <div class="data">
                        <input
                            [(ngModel)]="doctor.routerLink"
                            formControlName="routerLink"
                            id="routerLink"
                            class="form-control"
                            type="text"
                            required
                        />
                    </div>
                </div>
            </div>

            <div class="field-container">
                <div class="field">
                    <b>Orvos bemutatkozó szövege</b>
                    <div class="data">
                        <pw-htmleditor
                            [html]="doctor.introduction!"
                            [htmlForm]="htmlForm"
                            formControlName="introduction"
                            id="introduction"
                            class="form-control"
                            type="text"
                            required
                            ngDefaultControl
                        ></pw-htmleditor>
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <b>Kép</b>
                    <div class="data">
                        <div class="image-container">
                            <button [ngStyle]="{ background: 'lightgrey' }" onclick="document.getElementById('fileCover').click()">
                                Kép kiválasztása
                            </button>
                            <span *ngIf="updateCoverFile">{{ fileCover?.name }}</span>
                            <a href="{{ doctor.image }}" *ngIf="!updateCoverFile" target="_blank">Jelenlegi borítókép</a>
                            <mat-icon (click)="clearSelectedCoverFile()">delete_forever</mat-icon>
                            <input
                                [ngStyle]="{ display: 'none' }"
                                id="fileCover"
                                #fileCoverInput
                                class="form-control"
                                type="file"
                                (change)="onChange($event)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="contact-form">
        <h3>Munkakör hozzáadása</h3>
        <div class="add-icon">
            <mat-icon [ngStyle]="{ cursor: 'pointer' }" routerLink="/addjobtitle/{{ id }}">add_circle</mat-icon>
        </div>
        <div class="field-container">
            <div [ngStyle]="{ 'justify-content': 'center' }" class="field">
                <div [ngStyle]="{ 'flex-direction': 'row', width: '100%' }" class="data">
                    <div *ngFor="let doctorSpeciality of doctorSpecialityList; let i = index">
                        <div>
                            <input [placeholder]="doctorSpeciality.jobTitle" type="text" disabled />
                            <!-- <mat-form-field>
                                <mat-select [(ngModel)]="doctorSpeciality.jobTitle" id="statuses">
                                    <mat-option
                                        (click)="modifyUpdateDoctorSpecialitiesList(i, speciality)"
                                        *ngFor="let speciality of specialityList; let i = index"
                                        [value]="speciality.jobTitle"
                                    >
                                        {{ speciality.jobTitle }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="contact-form">
        <h3>Konzultációs idő módosítása</h3>
        <mat-icon (click)="addConsultationHour()" [ngStyle]="{ 'margin-bottom': '8px', cursor: 'pointer' }">add_circle</mat-icon>
        <form [formGroup]="consultingHourForm!" novalidate>
            <div class="array" formArrayName="items" *ngFor="let consultationHour of doctorConsultingHoursList; let i = index">
                <div
                    class="form-element"
                    [ngStyle]="i % 2 === 0 ? { background: 'white', display: 'flex' } : { background: '#efeff5', display: 'flex' }"
                    [formGroupName]="i"
                >
                    <div class="field-container">
                        <div class="field">
                            <b>Konzultáció napja</b>
                            <div class="data">
                                <input
                                    [(ngModel)]="consultationHour.day"
                                    formControlName="day"
                                    id="day"
                                    class="form-control"
                                    type="text"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div class="field-container">
                        <div class="field">
                            <span>Konzultáció kezdete</span>
                            <div *ngIf="consultationHour.startTime !== null" class="data">
                                <input
                                    [(ngModel)]="consultationHour.startTime"
                                    formControlName="startTime"
                                    id="startTime"
                                    class="form-control"
                                    type="text"
                                    required
                                />
                            </div>
                            <div *ngIf="consultationHour.startTime === null" class="data">
                                <input
                                    [(ngModel)]="consultationHour.startTime"
                                    formControlName="startTime"
                                    id="startTime"
                                    class="form-control"
                                    type="text"
                                    required
                                    placeholder="változó"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="field-container">
                        <div class="field">
                            <span>Konzultáció vége</span>
                            <div *ngIf="consultationHour.endTime !== null" class="data">
                                <input
                                    [(ngModel)]="consultationHour.endTime"
                                    formControlName="endTime"
                                    id="endTime"
                                    class="form-control"
                                    type="text"
                                    required
                                />
                            </div>
                            <div *ngIf="consultationHour.endTime === null" class="data">
                                <input
                                    [(ngModel)]="consultationHour.endTime"
                                    formControlName="endTime"
                                    id="endTime"
                                    class="form-control"
                                    type="text"
                                    required
                                    placeholder="változó"
                                />
                            </div>
                        </div>
                        <mat-icon [ngStyle]="{ cursor: 'pointer' }" (click)="openDeleteConsultingPopup(consultationHour.id!)"
                            >delete_forever</mat-icon
                        >
                    </div>
                </div>
            </div>
        </form>
        <div [ngStyle]="{ 'margin-top': '8px' }" class="field-container">
            <div [ngStyle]="{ 'justify-content': 'center', 'margin-top': '8px', 'margin-bottom': '8px' }" class="field">
                <div [ngStyle]="{ 'justify-content': 'center' }" class="data">
                    <button
                        (click)="updateDoctor()"
                        [ngStyle]="{
                            background: '#eee',
                            border: '1px solid #ccc',
                            padding: '8px',
                            'font-weight': 'bold',
                            width: '100%',
                            'border-radius': '4px',
                            cursor: 'pointer'
                        }"
                        [disabled]="(!doctorForm?.valid && !consultingHourForm?.valid) || !htmlForm?.valid"
                    >
                        ORVOS MÓDOSÍTÁSA
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
