import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Editor, Toolbar, Validators } from 'ngx-editor';
import { FormBuilder, FormGroup } from '@angular/forms';


@Component({
    selector: 'pw-htmleditor',
    templateUrl: './htmleditor.component.html',
    styleUrls: ['./htmleditor.component.scss']
})
export class HtmleditorComponent implements OnInit, OnDestroy {

    editor?: Editor;

    toolbar: Toolbar = [
        ['bold', 'italic'],
        ['underline', 'strike'],
        ['ordered_list', 'bullet_list'],
        [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
        ['link', 'image'],
        ['text_color', 'background_color'],
        ['align_left', 'align_center', 'align_right', 'align_justify']

    ];

    @Input() html = '';

    @Input() htmlForm?: FormGroup;


    get htmlElement() {
        return this.html;
    }
    ngOnInit(): void {
        this.editor = new Editor()

    }

    ngOnDestroy(): void {
        this.editor?.destroy();
    }


}


