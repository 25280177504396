import { Component, Inject, OnInit } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService } from '@prekog/pw-common';
import { Router } from '@angular/router';
import { FindPriceListRequest } from './findpricelistrequest';
import { FindPriceListResponse } from './findpricelistresponse';
import { Examination } from './examination';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UpdatePriceListRequest } from './updatepricelistrequest';
import { UpdatePriceListResponse } from './updatepricelistresponse';
import { GetSpecialityRequest } from './getspecialityrequest';
import { GetSpecialityResponse } from './getspecialityresponse';

@Component({
    selector: 'app-pricelist',
    templateUrl: './pricelist.component.html',
    styleUrls: ['./pricelist.component.scss']
})
export class PricelistComponent implements OnInit {

    findPriceListUrl = '/regenero/find-pricelist';

    examinationList?: Examination[];

    filteredExamination?: Examination[];

    regeneroBaseUrl = '';

    specialityId = 0;

    priceListForm?: FormGroup;

    specialityName = '';

    updatePriceListUrl = '/admin/update-pricelist';

    getSpecialityByIdUrl = '/admin/get-speciality-by-id';

    private _listFilter = '';

    get listFilter(): string {
        return this._listFilter;
    }
    set listFilter(value: string) {
        this._listFilter = value;
        this.filteredExamination = this.performFilter(value);
    }

    get items() {
        return this.priceListForm?.get('items') as FormArray;
    }

    constructor(
        public router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private _toastMessageService: ToastmessageService,
        private fb: FormBuilder
    ) {
        this.regeneroBaseUrl = _regeneroBaseUrl;
        this.createPriceListFormArray();
    }

    ngOnInit(): void {
        const lastSegmentOfUrl = window.location.href.split('/').pop();
        const routerLink = lastSegmentOfUrl as string;
        this.specialityId = +routerLink;
        this.findPriceList();
        this.getSpecialityById();

    }

    createPriceListFormArray() {
        this.priceListForm = this.fb.group({
            items: this.fb.array([])
        });
    }

    findPriceList() {
        const findPriceListRequest: FindPriceListRequest = {
            specialityId: this.specialityId
        };

        this._apiRequestService
            .sendRequest<FindPriceListRequest, FindPriceListResponse>(
                this.regeneroBaseUrl,
                true,
                findPriceListRequest,
                true,
                this.findPriceListUrl
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();

                        this.router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response: FindPriceListResponse) => {
                this.examinationList = response.examinationList;
                this.priceListForm = this.fb.group({
                    items: this.fb.array(this.examinationList!.map(examination => this.fb.group(examination)))
                });
                this.filteredExamination = this.examinationList;
                console.log(this.items.value as Examination[])
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }
    performFilter(filterBy: string): Examination[] {
        filterBy = filterBy.toLocaleLowerCase();
        return this.examinationList!.filter(
            (examination: Examination) =>
                examination.name?.toLocaleLowerCase().includes(filterBy)

        );
    }

    updatePriceList() {
        const updatePriceListRequest:UpdatePriceListRequest = {
            examinationList: this.items.value as Examination[]
        }

        this._apiRequestService
            .sendRequest<UpdatePriceListRequest, UpdatePriceListResponse>(
                this.regeneroBaseUrl,
                true,
                updatePriceListRequest,
                true,
                this.updatePriceListUrl
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();

                        this.router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response: UpdatePriceListResponse) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage!, MAT_ICON.success);
                this.dismissError();
            });
    }

    getSpecialityById() {

        const getspecialitybyidRequest: GetSpecialityRequest = {
            specialityId: this.specialityId
        };
        this._apiRequestService
        .sendRequest<GetSpecialityRequest, GetSpecialityResponse>(
            this.regeneroBaseUrl,
            true,
            getspecialitybyidRequest,
            true,
            this.getSpecialityByIdUrl
        )
        .pipe(
            catchError((error: HttpErrorResponse) => {
                return throwError(() => {
                    const httpError: HttpError = error.error as HttpError;

                    this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                    this.dismissError();

                    this.router.navigateByUrl('/');
                });
            })
        )
        .subscribe((response: GetSpecialityResponse) => {
            this.specialityName = response.specialityName!;
        });
    }
}
