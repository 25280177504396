import { Component, Inject, OnInit } from '@angular/core';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService } from '@prekog/pw-common';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Speciality } from '../specialitypricelist/speciality';
import { GetSpecialitiesResponse } from '../specialitypricelist/getspecialitiesresponse';

@Component({
  selector: 'pw-specialities',
  templateUrl: './specialities.component.html',
  styleUrls: ['./specialities.component.scss']
})
export class SpecialitiesComponent implements OnInit {

    getSpecialitiesUrl = '/regenero/get-specialities';

    specialities?: Speciality[];

    regeneroBaseUrl = '';


    filteredSpeciality: Speciality[] = [];

    private _listFilter = '';

    get listFilter(): string {
        return this._listFilter;
    }
    set listFilter(value: string) {
        this._listFilter = value;
        this.filteredSpeciality = this.performFilter(value);
    }

    constructor(
        public router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private _toastMessageService: ToastmessageService
    ) {

        this.regeneroBaseUrl = _regeneroBaseUrl;
    }
    ngOnInit(): void {
        this.getSpecialities();
    }

    getSpecialities() {
        this._apiRequestService
            .sendRequest<{}, GetSpecialitiesResponse>(this.regeneroBaseUrl, true, {}, true, this.getSpecialitiesUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();

                        this.router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response: GetSpecialitiesResponse) => {
                this.specialities = response.specialityList;
                this.filteredSpeciality = this.specialities!;
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    performFilter(filterBy: string): Speciality[] {
        filterBy = filterBy.toLocaleLowerCase();
        return this.specialities!.filter(
            (speciality: Speciality) =>
                speciality.name?.toLocaleLowerCase().includes(filterBy)

        );
    }

}
