<div class="specialities">
    <div class="title">
        <div class="tit">
            <h3>ORVOSOK</h3>
            <div class="add-icon">
                <mat-icon routerLink="/adddoctor">add_circle</mat-icon>
            </div>
        </div>
    </div>

    <div class="add-new-speciality">
        <div class="search-bar">
            <div class="search-tool">
                <input [(ngModel)]="listFilter" type="search" placeholder="Orvos neve" />
                <div class="icon">
                    <mat-icon aria-hidden="false" aria-label="keyboard_arrow_right icon" fontIcon="search"></mat-icon>
                </div>
            </div>
        </div>
    </div>
    <div class="speciality-container">
        <div class="speciality-c">
            <div *ngFor="let doctor of filteredDoctors; let i = index" class="speciality">
                <div class="pro">
                    <div
                        [ngStyle]="
                            i % 2 === 0 ? { background: 'white', border: '1px solid #ccc' } : { background: '#efeff5', border: 'black' }
                        "
                        class="name"
                    >
                        <div
                            [ngStyle]="i % 2 === 0 ? { background: 'white' } : { background: '#efeff5' }"
                            class="button"
                            routerLink="/doctor/{{ doctor.id }}"
                        >
                            {{ doctor.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
