<div class="popup-main">
    <div class="popup">
        <div class="mat-dialog-actions" mat-dialog-actions [align]="selectedAlign">
            <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
        </div>
        <div class="text">
            <h3>Biztos benne, hogy törli a konzultációs időt?</h3>
        </div>
        <div class="back-and-to-buttons">
            <button (click)="closeDialog()">Nem</button>
            <button (click)="deleteConsultingHour()">Igen</button>
        </div>
    </div>
</div>
