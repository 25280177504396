<div class="banner-container">
    <div class="back-button">
        <mat-icon routerLink="/banners">arrow_back</mat-icon>
    </div>
    <div class="banners">
        <div class="title-container">
            <div class="title">
                <h3>BANNEREK RENDEZÉSE (DRAG AND DROP)</h3>
            </div>
        </div>
        <div class="example-box" cdkDrag>
            <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                <div class="example-box" *ngFor="let banner of banners; let i = index" cdkDrag>
                    <div class="order-number">
                        <span>{{ i + 1 }}</span>
                    </div>
                    <div class="title-banner">
                        <span>{{ banner.title }}</span>
                    </div>
                    <div class="image-url">
                        <a href="{{ banner.imageUrl }}">Megtekintés</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div [ngStyle]="{ 'margin-top': '8px' }" class="field-container">
        <div [ngStyle]="{ 'justify-content': 'center', 'margin-top': '8px', 'margin-bottom': '8px' }" class="field">
            <div [ngStyle]="{ 'justify-content': 'center', width: '70%' }" class="data">
                <button
                    (click)="updateBannerOrder()"
                    [ngStyle]="{ background: '#efeff5', border: '1px solid #ccc', 'font-weight': 'bold', width: '100%' }"
                >
                    Bannerek rendezése
                </button>
            </div>
        </div>
    </div>
</div>
