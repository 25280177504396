<div class="app">
    <lib-toastmessage></lib-toastmessage>
    <pw-navigationmodule
        *ngIf="router.url !== '/'"
        [nameOfTheSite]="title"
        [logoPosition]="positionL"
        [hasLogo]="false"
        [isShoppingCart]="false"
        [hasLogin]="hasLogin"
        [colorOfNavBar]="colorOfNavBar"
        [isAdmin]="true"
        [adminHomeUrl]="adminHomeUrl"
    ></pw-navigationmodule>
    <!-- <pw-breadcrumbmodule></pw-breadcrumbmodule> -->
    <div class="router-outlet">
        <router-outlet (activate)="loadRouterOutletInput($event)"></router-outlet>
    </div>
</div>
<div class="footer-container">
    <div class="footer-admin">
        <h1></h1>
    </div>
</div>
