<div class="contact-content">
    <div class="back-button">
        <mat-icon routerLink="/doctor/{{id}}">arrow_back</mat-icon>
    </div>
    <div>
        <h3>{{ doctorName | uppercase }} - ÚJ MUNKAKÖR HOZZÁADÁSA</h3>
    </div>
    <div class="contact-form">
        <form [formGroup]="specialityForm!" novalidate>
            <div class="field-container">
                <div class="field">
                    <span>Munkakör neve</span>
                    <div class="data">
                        <input [(ngModel)]="speciality.name" formControlName="name" id="name" class="form-control" type="text" required />
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Orvos munkaköre</span>
                    <div class="data">
                        <input
                            [(ngModel)]="speciality.jobTitle"
                            formControlName="jobTitle"
                            id="jobTitle"
                            class="form-control"
                            type="text"
                            required
                        />
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div [ngStyle]="{ 'margin-top': '8px' }" class="field-container">
        <div [ngStyle]="{ 'justify-content': 'center', 'margin-top': '8px', 'margin-bottom': '8px' }" class="field">
            <div [ngStyle]="{ 'justify-content': 'center' }" class="data">
                <button
                    (click)="addJobTitle()"
                    [ngStyle]="{ background: '#eee', border: '1px solid #0E8388', 'font-weight': 'bold', width: '100%' }"
                    [disabled]="!specialityForm?.valid"
                >
                    MUNKAKÖR MENTÉSE
                </button>
            </div>
        </div>
    </div>
</div>
