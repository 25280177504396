import { Component, ElementRef, Inject } from '@angular/core';
import {
    ApiRequestService,
    LanguageService,
    TranslatableComponent,
    TranslationService,
    TranslationStore,
    ToastmessageService,
    SigninComponent
} from '@prekog/pw-common';
import { GetTranslationUseCase } from './usecases/translation-usecase/read-translation-usecase';
import { Router } from '@angular/router';
import { GetTranslationRequest } from './usecases/translation-usecase/read-translation-request';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NavigationMenuItem } from './navigation-menu';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends TranslatableComponent {
    title = 'Regenero Egészségház';

    cookieValue = '';

    loading = false;

    logoUrl = '';

    nameOfTheSite = 'Regenero Egészségház Management';

    production = false;

    downloadFileUrl = '';

    colorOfNavBar = 'black';

    logoSize = '';

    regeneroBaseUrl = '';

    navigationMenu: NavigationMenuItem[] = [];

    isSubMenuFullScreen = true;

    isRowView = false;

    colorOfMobileSidenav = "lightorange"

    positionL = 'PositionL';

    hasLogin = false;

    adminHomeUrl = '/home';

    constructor(
        private _translateService: TranslationService,
        private _http: HttpClient,
        private _readTranslationfileUseCase: GetTranslationUseCase,
        public router: Router,
        private _apiRequestService: ApiRequestService,
        private _languageService: LanguageService,
        @Inject('PRODUCTION') private _production: boolean,
        @Inject('DOWNLOAD_FILE_URL') private _downloadFileUrl: string,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private elem: ElementRef,
        private _toastMessageService: ToastmessageService
    ) {
        super(_translateService, _http);
        this.production = _production;
        this.downloadFileUrl = _downloadFileUrl;
        this.logoUrl = this.downloadFileUrl + '/20240626T101258865160117';
        this.logoSize = '200px';
        this.elem.nativeElement.style.setProperty('--logo-size', this.logoSize);
        this.regeneroBaseUrl = _regeneroBaseUrl;
    }

    override readTranslations(http: HttpClient, language: string): Observable<TranslationStore> {
        const request: GetTranslationRequest = { url: './assets/i18n/' + language + '.json' };
        this._readTranslationfileUseCase.execute(request) as unknown as Observable<TranslationStore>;
        return this._http.get<TranslationStore>('./assets/i18n/' + language + '.json');
    }

    override onInit(): void {
        if (!sessionStorage.getItem('token')) {
            this.router.navigateByUrl('/');
        }

    }

    loadRouterOutletInput(component: SigninComponent) {
        if (component instanceof SigninComponent) {
            component.admin = true;
            component.hasCityAndZipUrl = false;
            component.redirectUrl = '/home'
        }
    }

}
