import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';

if (environment.production) {
    enableProdMode();
}


export function getApiUrl(): string {
    return environment.apiUrl;
}

export function getProduction(): boolean {
    return environment.production;
}

export function getDownloadFileUrl(): string {
    return environment.downloadUrl;
}

export function getConfiguratorUrl(): string {
    return environment.backendConfiguratorUrl;
}

export function getRegeneroUrl(): string {
    return environment.regeneroBaseUrl;
}

export function getBackendAuthUrl(): string {
    return environment.backendAuthUrl;
}

export function getFileManagerUrl(): string {
    return environment.backendFileManagerUrl;
}

const providers = [
    { provide: 'API_URL', useFactory: getApiUrl, deps: [] },
    { provide: 'DOWNLOAD_FILE_URL', useFactory: getDownloadFileUrl, deps: [] },
    { provide: 'CONFIGURATOR_BASE_URL', useFactory: getConfiguratorUrl, deps: [] },
    { provide: 'PRODUCTION', useFactory: getProduction(), deps: [] },
    { provide: 'REGENERO_BASE_URL', useFactory: getRegeneroUrl(), deps: [] },
    { provide: 'AUTH_BACKEND_URL', useFactory: getBackendAuthUrl, deps: [] },
    { provide: 'FILE_MANAGER_URL', useFactory: getDownloadFileUrl, deps: [] }
];

platformBrowserDynamic()
.bootstrapModule(AppModule)
.catch(err => console.error(err));
