import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DoctorConsultingHour } from '../updatedoctor/doctorconsultinghour';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-addconsultinghourpopup.ts',
    templateUrl: './addconsultinghourpopup.ts.component.html',
    styleUrls: ['./addconsultinghourpopup.ts.component.scss']
})
export class AddconsultinghourpopupTsComponent implements OnInit {

    selectedAlign: 'start' | 'center' | 'end';

    consultationForm?: FormGroup;

    doctorConsultingHour: DoctorConsultingHour = {};

    doctorConsultingHoursList: DoctorConsultingHour[] = [];

    constructor(
        private _matdialog: MatDialog,
        private _fb: FormBuilder
    ) {

        this.selectedAlign = 'end';
        this.createConsultationHourForm()
    }

    ngOnInit(): void {
          if(sessionStorage.getItem('doctorConsultationList')) {
            this.doctorConsultingHoursList = JSON.parse(sessionStorage.getItem('doctorConsultationList') as string) as DoctorConsultingHour[];
        }
    }


    createConsultationHourForm() {
        this.consultationForm = this._fb.group({
            day: ['', [Validators.required]],
            startTime: ['', [Validators.required]],
            endTime: ['', [Validators.required]]
        });
    }


    collectConsultationHourData() {
        this.doctorConsultingHour = {
            day: this.consultationForm?.controls['day'].value as string | undefined,
            startTime: this.consultationForm?.controls['startTime'].value as string | undefined,
            endTime: this.consultationForm?.controls['endTime'].value as string | undefined,

        };

    }

    addConsultation() {
        this.collectConsultationHourData();
        this.doctorConsultingHoursList.push(this.doctorConsultingHour);
        sessionStorage.setItem('doctorConsultationList',JSON.stringify(this.doctorConsultingHoursList));
        this.closeDialog();
    }

    closeDialog() {
        this._matdialog.closeAll();
    }
}
