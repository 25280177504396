import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService, TranslationService } from '@prekog/pw-common';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { Speciality } from '../../specialitypricelist/speciality';
import { AddSpecialityRequest } from './addspecialityrequest';
import { AddSpecialityResponse } from './addspecialityresponse';

@Component({
  selector: 'app-addspeciality',
  templateUrl: './addspeciality.component.html',
  styleUrls: ['./addspeciality.component.scss']
})
export class AddspecialityComponent {

    specialityForm?: FormGroup;

    htmlForm?: FormGroup;

    speciality: Speciality = {};

    regeneroBaseUrl = '';

    id = 0;

    addSpecialityUrl = '/admin/add-speciality';

    html = '';

    constructor(
        public _router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private _toastMessageService: ToastmessageService,
        private _fb: FormBuilder
    ) {
        this.regeneroBaseUrl = _regeneroBaseUrl;

        this.createSpecialityForm();

        this.createHtmlForm();

    }

    createSpecialityForm() {
        this.specialityForm = this._fb.group({
            name: ['', [Validators.required]],
            jobTitle: ['', [Validators.required]]


        });
    }

    createHtmlForm() {
        this.htmlForm = this._fb.group({
            html: ['', [Validators.required]],

        });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    collectSpecialityData() {
        this.speciality = {
            name: this.specialityForm?.controls['name'].value as string | undefined,
            jobTitle: this.specialityForm?.controls['jobTitle'].value as string | undefined,
            description: this.htmlForm?.controls['html'].value as string | undefined,
            isSpeciality:true

        };

    }

    addSpeciality() {
        this.collectSpecialityData();
        const addSpecialityRequest: AddSpecialityRequest = {
            speciality: this.speciality
        }

        this._apiRequestService
            .sendRequest<AddSpecialityRequest, AddSpecialityResponse>(this.regeneroBaseUrl, true, addSpecialityRequest, true, this.addSpecialityUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response: AddSpecialityResponse) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage!, MAT_ICON.success);
                this.dismissError();
                this._router.navigateByUrl('/specialities');


            });
    }

}
