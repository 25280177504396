<div class="popup-main">
    <div class="popup">
        <div class="mat-dialog-actions" mat-dialog-actions [align]="selectedAlign">
            <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
        </div>
        <div class="contact-content">
            <h3>Új konzultációs idő hozzáadása</h3>
            <div class="contact-form">
                <form [formGroup]="consultationForm!" novalidate>
                    <div class="field-container">
                        <div class="field">
                            <span>Konzultáció napja</span>
                            <div class="data">
                                <input
                                    [(ngModel)]="doctorConsultingHour.day"
                                    formControlName="day"
                                    id="day"
                                    class="form-control"
                                    type="text"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div class="field-container">
                        <div class="field">
                            <span>Konzultáció kezdete</span>
                            <div class="data">
                                <input
                                    [(ngModel)]="doctorConsultingHour.startTime"
                                    formControlName="startTime"
                                    id="startTime"
                                    class="form-control"
                                    type="time"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div class="field-container">
                        <div class="field">
                            <span>Konzultáció vége</span>
                            <div class="data">
                                <input
                                    [(ngModel)]="doctorConsultingHour.endTime"
                                    formControlName="endTime"
                                    id="endTime"
                                    class="form-control"
                                    type="time"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div [ngStyle]="{ 'margin-top': '8px' }" class="field-container">
                <div [ngStyle]="{ 'justify-content': 'center', 'margin-top': '8px', 'margin-bottom': '8px' }" class="field">
                    <div [ngStyle]="{ 'justify-content': 'center', width: '70%' }" class="data">
                        <button
                            (click)="addConsultation()"
                            [ngStyle]="{ background: '#eee', border: '1px solid #0E8388', 'font-weight': 'bold', width: '100%' }"
                            [disabled]="!consultationForm?.valid"
                        >
                            KONZULTÁCIÓS IDŐ HOZZÁADÁSA
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
